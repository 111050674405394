svg#freepik_stories-online-learning:not(.animated) .animable {
    opacity: 0;
}

svg#freepik_stories-online-learning.animated #freepik--background-simple--inject-11 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

svg#freepik_stories-online-learning.animated #freepik--Window--inject-11 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-online-learning.animated #freepik--book-shelf--inject-11 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
    animation-delay: 0s;
}

svg#freepik_stories-online-learning.animated #freepik--Screen--inject-11 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn, 1.5s Infinite linear floating;
    animation-delay: 0s, 1s;
}

svg#freepik_stories-online-learning.animated #freepik--Character--inject-11 {
    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) lightSpeedLeft;
    animation-delay: 0s;
}

@keyframes lightSpeedLeft {
    from {
        transform: translate3d(-50%, 0, 0) skewX(20deg);
        opacity: 0;
    }

    60% {
        transform: skewX(-10deg);
        opacity: 1;
    }

    80% {
        transform: skewX(2deg);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes floating {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
